.container {
  padding: 0.5rem;
  text-align: center;
  background: linear-gradient(270deg, #ff47aa 0%, #aa56ff 30%, #418dff 80%);
  color: #ffffff;
  span {
    color: #42ddff;
    font-size: 0.9rem;
    text-decoration: underline;
    cursor: pointer;
  }
}

