.container {
  position: relative;
  > .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
  > .outClose {
    top: -25px;
    right: 0px;
  }
}

